import { createStore } from "vuex";
import Storage from "../common/storage";
import { ID_ACCESS_TOKEN_KEY, ID_USER_KEY, ID_LANGUAGE_KEY } from "../common/constants";

/**
 * services
 */
import services from "@/services";

export default createStore({
  state: {
    token: {
      accessToken: Storage.get(ID_ACCESS_TOKEN_KEY)
    },
    user: Storage.get(ID_USER_KEY),
    isAuthenticated: !!Storage.get(ID_ACCESS_TOKEN_KEY),
    language: Storage.get(ID_LANGUAGE_KEY),
    page: {
      title : ''
    },
  },
  getters: {
    getLanguage: function (state) {
      return state.language;
    },
    getAccessToken: function(state) {
      return state.token.accessToken;
    },
    getUser: function(state) {
      console.log('state.userstate.userstate.user::::::::');
      console.log(state.user);
      return state.user;
    },
    isAuthenticated: function(state) {
      return state.isAuthenticated;
    },
    getPage:function(state) {
      // 페이지 정보를 반환한다.
      return state.page;
    }
  },
  mutations: {
    user: function (state: any, payload = {}) {
      state.user = payload;
      console.log(payload);
      Storage.save(ID_USER_KEY, payload)
    },
    login: function(state: any, payload = {}) {
      state.token.accessToken = payload.accessToken;
      state.isAuthenticated = true;
      Storage.save(ID_ACCESS_TOKEN_KEY, payload.accessToken)
    },
    language: function(state: any, payload = {}) {
      state.language = payload.language;
      Storage.save(ID_LANGUAGE_KEY, payload.language)
    },
    logout: function(state: any) {
      state.token.accessToken = '';
      state.isAuthenticated = false;
      Storage.destroy();
    },
    page(state: any, payload = {}) {
      console.log(state);
      console.log(payload);
      state.page.title = payload.title; // 페이지 타이틀을 저장한다.
    },
  },
  actions: {
    logout: function(context) {
      return new Promise((resolve, reject) => {
        console.log(resolve);
        console.log(reject);

        services.authLogout().then(
          response => {
            const { data } = response;
            if (response.status == 204) {
              console.log(context);
              console.log(data);
              context.commit("logout");
            }

            resolve(response);
          },
          error => {
            // status 404
            const { data } = error.response;
            reject(data.message);
          }
        );
      })
    },
    login: function(context, payload) {
      const params: any = {};
      console.log(payload);

      params.email = payload.email;
      params.password = payload.password;

      return new Promise((resolve, reject) => {
        console.log(resolve);
        console.log(reject);

        services.authLogin(params).then(
          response => {
            const { data } = response;
            if (response.status == 200) {
              console.log(context);
              console.log(data);
              context.commit("login", {
                accessToken: data.accessToken
              });
            }

            resolve(response);
          },
          error => {
            // status 404
            const { data } = error.response;
            reject(data.message);
          }
        );
      })
    },
    register: function(context, payload) {
      const params: any = {};

      params.email = payload.email;
      params.password = payload.password;
      params.nickname = payload.nickname;
      params.termsYn = payload.termsYn;
      params.privacyYn  = payload.privacyYn ;
      params.marketingYn  = payload.marketingYn ;
      params.thirdpartyYn  = payload.thirdpartyYn ;

      return new Promise((resolve, reject) => {
        console.log(resolve);
        console.log(reject);

        services.authRegister(params).then(
          response => {
            const { data } = response;
            if(response.status == 200) {
              console.log(context);
              console.log(data);
              context.commit("login", {
                accessToken: data.accessToken
              });
            }
            resolve(response);
          },
          error => {
            const { data } = error.response;
            reject(data.message);
          }
        )
      })
    },
    recover: function(context, payload) {
      const params: any = {};
      params.email = payload.email;

      return new Promise((resolve, reject) => {
        console.log(resolve);
        console.log(reject);
        services.authRecover(params).then(
          response => {
            const { data } = response;
            if(response.status == 204) {
              console.log(context);
              console.log(data);
            }
            resolve(response);
          },
          error => {
            const { data } = error.response;
            reject(data.message);
          }
        )
      })
    },
  }
});