import http from "@/common/http";
import { SERVICE_API_URL } from "@/common/config";

const findAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/data/shoes", {
    params: params
  });
};

const findUser = (id: number, params: object) => {
  return http.get(SERVICE_API_URL + `/data/shoes/user/${id}`, {
    params: params
  });
};

const findOne = (id: number) => {
  return http.get(SERVICE_API_URL + `/data/shoes/${id}`);
};

const add = (params: object) => {
  return http.post(SERVICE_API_URL + "/data/shoes", params);
};

const modify = (id: number, params: object ) => {
  return http.put(SERVICE_API_URL + `/data/shoes/${id}`, params);
};

const remove = (id: number) => {
  return http.delete(SERVICE_API_URL + `/data/shoes/${id}`);
};

export default {
  findAll,
  findUser,    
  findOne,
  add,
  modify,
  remove
}