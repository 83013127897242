
import { IonTabBar, IonTabButton, IonTabs, IonLabel, IonIcon, IonPage, IonRouterOutlet } from '@ionic/vue';
import { statsChartOutline, analyticsOutline, chatbubbleEllipsesOutline, personOutline, ellipsisHorizontalOutline } from 'ionicons/icons';

export default {
  components: { IonLabel, IonTabs, IonTabBar, IonTabButton, IonIcon, IonPage, IonRouterOutlet },
  setup() {
    return {
      statsChartOutline,
      analyticsOutline,
      chatbubbleEllipsesOutline,
      personOutline,
      ellipsisHorizontalOutline
    }
  }
}
