import { createI18n } from "vue-i18n";

function loadLocaleMessages() {
  const locales = require.context(
    "./locales/message",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      Object.assign(messages, {[locale]: locales(key)});
    }
  });

  return messages;
}

function loadLocalDateTimeFormats() {
  const locales = require.context(
    "./locales/date",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      Object.assign(messages, {[locale]: locales(key)});
    }
  });

  return messages;
}

function loadLocalNumberFormats() {
  const locales = require.context(
    "./locales/number",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      Object.assign(messages, {[locale]: locales(key)});
    }
  });

  return messages;
}

const i18n = createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: ["ko", "ja", "zh"],
  messages: loadLocaleMessages(),
  dateTimeFormats: loadLocalDateTimeFormats(),
  numberFormats: loadLocalNumberFormats()
})

export default i18n;