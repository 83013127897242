import http from "@/common/http";
import { SERVICE_API_URL } from "@/common/config";

const findOne = (id: number) => {
  return http.get(SERVICE_API_URL + `/brand/${id}`);
};

const findAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/brand", {
    params: params
  });
};


export default {
  findOne,
  findAll
}