export const API_URL =
  process.env.NODE_ENV == "development"
    ? "https://api.sales.nextinnovation.kr"
    : "https://api.sales.nextinnovation.kr";

export const DOAMIN_URL =
  process.env.NODE_ENV == "development"
    ? "https://sales.nextinnovation.kr"
    : "https://sales.nextinnovation.kr";

export const SERVICE_API_URL = "/api/v1/app";

export const VERSION = {
  android: {
    packageName: 'kr.nextinnovation.salesboardmvp',
    number: 1,
    code: '1.0.0'
  },
  ios: {
    packageName: 'kr.nextinnovation.salesboardmvp',
    number: 1,
    code: '1.0.0'
  },
  web: {
    packageName: 'kr.nextinnovation.salesboardmvp',
    number: 1,
    code: '1.0.0'
  }
};