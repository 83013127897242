import http from "@/common/http";
import { SERVICE_API_URL } from "@/common/config";

const findOne = (id: number) => {
  return http.get(SERVICE_API_URL + `/user/${id}`);
};

const patch = (params: object) => {
  return http.patch(SERVICE_API_URL + "/user", params);
};

const findAll = (params: object) => {
  return http.get(SERVICE_API_URL + "/user", {
    params: params
  });
};

export default {
  findOne,
  patch,
  findAll
}