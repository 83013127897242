import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import MainLayout from '../layout/MainLayout.vue'

// store
import store from "../store";

const title = "온오피핏";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        meta: {
          authRequired: true,
          title: title + "홈",
          breadcrumb: [{ name: "홈", link: "" }]
        },
        component: () => import('@/views/dashboard/DashboardPage.vue')
      },
      {
        path: 'client',
        name: 'Client',
        meta: {
          authRequired: true,
          title: title + "고객정보",
          breadcrumb: [{ name: "고객정보", link: "" }]
        },
        component: () => import('@/views/client/ClientPage.vue')
      },      
      {
        path: 'post',
        name: 'Post',
        meta: {
          authRequired: true,
          title: title + "소식",
          breadcrumb: [{ name: "소식", link: "" }]
        },
        component: () => import('@/views/main/post/PostPage.vue')
      },
      {
        path: 'mypage',
        name: 'Mypage',
        meta: {
          authRequired: true,
          title: title + "마이페이지",
          breadcrumb: [{ name: "마이페이지", link: "" }]
        },
        component: () => import('@/views/main/mypage/MypagePage.vue')
      },
      {
        path: 'more',
        name: 'More',
        meta: {
          authRequired: false,
          title: title + "더보기",
          breadcrumb: [{ name: "더보기", link: "" }]
        },
        component: () => import('@/views/main/more/MorePage.vue')
      }
    ]
  },
  // Client
  {
    path: '/client/editor',
    name: 'ClientEditor',
    component: () => import('@/views/client/editor/EditorPage.vue'),
    meta: {
      authRequired: true,
      title: title + "고객정보 등록",
      breadcrumb: [{ name: "고객정보 등록", link: "" }]
    }
  },  
  // Data
  {
    path: '/data/shoes/editor',
    name: 'DataShoesEditor',
    component: () => import('@/views/data/shoes/editor/EditorPage.vue'),
    meta: {
      authRequired: true,
      title: title + "신발 등록",
      breadcrumb: [{ name: "신발 등록", link: "" }]
    }
  },
  // Doc
  {
    path: '/maketing',
    name: 'Maketing',
    component: () => import('@/views/doc/DocPage.vue'),
    meta: {
      authRequired: false,
      title: title + "마케팅 이용동의",
      breadcrumb: [{ name: "마케팅 이용동의", link: "" }]
    }
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('@/views/doc/DocPage.vue'),
    meta: {
      authRequired: false,
      title: title + "개인정보 처리방침",
      breadcrumb: [{ name: "개인정보 처리방침", link: "" }]
    }
  },
  {
    path: '/third-party',
    name: 'ThirdParty',
    component: () => import('@/views/doc/DocPage.vue'),
    meta: {
      authRequired: false,
      title: title + "개인정보 제3자 제공동의",
      breadcrumb: [{ name: "개인정보 제3자 제공동의", link: "" }]
    }
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('@/views/doc/DocPage.vue'),
    meta: {
      authRequired: false,
      title: title + "이용 약관",
      breadcrumb: [{ name: "이용 약관", link: "" }]
    }
  },
  {
    path: '/open-source',
    name: 'OpenSource',
    component: () => import('@/views/doc/DocPage.vue'),
    meta: {
      authRequired: false,
      title: title + "오픈 소스 라이브러리",
      breadcrumb: [{ name: "오픈 소스 라이브러리", link: "" }]
    }
  },
  // Post
  {
    path: '/post/:id',
    name: 'PostId',
    component: () => import('@/views/post/id/PostIdPage.vue'),
    meta: {
      authRequired: false,
      title: title + "소식",
      breadcrumb: [{ name: "소식", link: "" }]
    }
  },
  //Setting
  {
    path: '/setting',
    name: 'Setting',
    component: () => import('@/views/setting/SettingPage.vue'),
    meta: {
      authRequired: true,
      title: title + "설정",
      breadcrumb: [{ name: "설정", link: "" }]
    }
  },
  {
    path: '/setting/profile',
    name: 'SettingProfile',
    component: () => import('@/views/setting/profile/ProfilePage.vue'),
    meta: {
      authRequired: true,
      title: title + "설정 - 회원정보 수정",
      breadcrumb: [{ name: "설정", link: "" }]
    }
  },
  {
    path: '/setting/nickname',
    name: 'SettingNickname',
    component: () => import('@/views/setting/nickname/NicknamePage.vue'),
    meta: {
      authRequired: true,
      title: title + "설정",
      breadcrumb: [{ name: "설정 - 닉네임 변경", link: "" }]
    }
  },
  {
    path: '/setting/password',
    name: 'SettingPassword',
    component: () => import('@/views/setting/password/PasswordPage.vue'),
    meta: {
      authRequired: true,
      title: title + "설정",
      breadcrumb: [{ name: "설정 - 비밀번호 변경", link: "" }]
    }
  },
  //Auth
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/auth/login/LoginPage.vue'),
    meta: {
      authRequired: false,
      isAuthPage: true,
    }
  },
  {
    path: '/recover',
    name: 'Recover',
    component: () => import('@/views/auth/recover/RecoverPage.vue'),
    meta: {
      authRequired: false,
      isAuthPage: true,
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/auth/register/RegisterPage.vue'),
    meta: {
      authRequired: false,
      isAuthPage: true,
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  console.log(store.getters["isAuthenticated"]);
  const isAuthenticated = store.getters["isAuthenticated"];
  console.log(from);
  if (
    to.matched.some(function(routeInfo) {
      return routeInfo.meta.authRequired;
    })
  ) {
    // 인증이 필요한 페이지일 경우 인증 체크
    if (isAuthenticated) {
      next();
    } else {
      next({ name: "Login" });
    }
  } else {
    if (Object.prototype.hasOwnProperty.call(to.meta, "authRequired")) {
      if (isAuthenticated && Object.prototype.hasOwnProperty.call(to.meta, "isAuthPage")) {
        next({ name: "Dashboard" });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router
