import authService from "./auth.service";
import postService from "./post.service";
import clientService from "./client.service";
import userService from "./user.service";
import dataShoesService from "./data/shoes.service";
import brandService from "./brand.service";
import categoryService from "./category.service";
import commentService from "./comment.service";
import likeService from "./like.service";

export default {
  // likeService
  likeFindAll(params: object = {}) {
    return likeService.findAll(params);            
  },
  
  likeFindPost(id: number, params: object = {}) {
    return likeService.findPost(id, params);            
  },
  
  likeFindOne(id: number) {
    return likeService.findOne(id);            
  },
  
  likeAdd(params: object = {}) {
    return likeService.add(params);        
  },
  
  likeModify(id: number, params: object = {}) {
    return likeService.modify(id, params);    
  },
  
  likeRemove(params: object = {}) {
    return likeService.remove(params);
  },

  // commentService
  commentFindAll(params: object = {}) {
    return commentService.findAll(params);            
  },
  
  commentFindPost(id: number, params: object = {}) {
    return commentService.findPost(id, params);            
  },
  
  commentFindOne(id: number) {
    return commentService.findOne(id);            
  },
  
  commentAdd(params: object = {}) {
    return commentService.add(params);        
  },
  
  commentModify(id: number, params: object = {}) {
    return commentService.modify(id, params);    
  },
  
  commentRemove(id: number) {
    return commentService.remove(id);
  },

  // categoryService
  categoryFindAll(params: object = {}) {
    return categoryService.findAll(params);            
  },
  
  categoryFindOne(id: number) {
    return categoryService.findOne(id);            
  },

  // brandService
  brandFindAll(params: object = {}) {
    return brandService.findAll(params);            
  },
  
  brandFindOne(id: number) {
    return brandService.findOne(id);            
  },

  // dataShoesService
  dataShoesFindAll(params: object = {}) {
    return dataShoesService.findAll(params);            
  },
  
  dataShoesFindUser(id: number, params: object = {}) {
    return dataShoesService.findUser(id, params);            
  },
  
  dataShoesFindOne(id: number) {
    return dataShoesService.findOne(id);            
  },
  
  dataShoesAdd(params: object = {}) {
    return dataShoesService.add(params);        
  },
  
  dataShoesModify(id: number, params: object = {}) {
    return dataShoesService.modify(id, params);    
  },
  
  dataShoesRemove(id: number) {
    return dataShoesService.remove(id);
  },

  // authService
  authLogin(params: object = {}) {
    return authService.login(params);
  },
  authRegister(params: object = {}) {
    return authService.register(params);
  },
  authRecover(params: object = {}) {
    return authService.recover(params);
  },
  authUser() {
    return authService.user();
  },
  authWithdrawal(params: object = {}) {
    return authService.withdrawal(params);
  },
  authLogout() {
    return authService.logout();
  },  

  // clientService
  clientFindAll(params: object = {}) {
    return clientService.findAll(params);            
  },
  
  clientFindUser(id: number, params: object = {}) {
    return clientService.findUser(id, params);            
  },
  
  clientFindOne(id: number) {
    return clientService.findOne(id);            
  },
  
  clientAdd(params: object = {}) {
    return clientService.add(params);        
  },
  
  clientModify(id: number, params: object = {}) {
    return clientService.modify(id, params);    
  },
  
  clientRemove(id: number) {
    return clientService.remove(id);
  },

  // postService
  postFindAll(params: object = {}) {
    return postService.findAll(params);            
  },
  
  postFindUser(id: number, params: object = {}) {
    return postService.findUser(id, params);            
  },
  
  postFindOne(id: number) {
    return postService.findOne(id);            
  },
  
  postAdd(params: object = {}) {
    return postService.add(params);        
  },
  
  postModify(id: number, params: object = {}) {
    return postService.modify(id, params);    
  },
  
  postRemove(id: number) {
    return postService.remove(id);
  },
  
  // userService
  userPatch(params: object = {}) {
    return userService.patch(params);
  },
  userFindOne(id = 0) {
    return userService.findOne(id);
  },
  userFindAll(params: object = {}) {
    return userService.findAll(params);
  },  
}