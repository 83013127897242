import axios from "axios";
import { API_URL } from "@/common/config";
import responseInterceptors from "./http.interceptors.response";
import store from "../store";

const http = axios.create({
  baseURL: API_URL
});

http.interceptors.request.use(
  config => {
    const accessToken = store.getters["getAccessToken"];

    config.headers = {
      "Content-Type": "application/json",
      "X-AUTH-TOKEN": "Bearer " + accessToken
    }

    return config;
  },
  error => {
    Promise.reject(error);
  }
);

http.interceptors.response.use(undefined, responseInterceptors(http));

export default http;
