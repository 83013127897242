
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent, ref, provide } from 'vue';

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  setup() {
    // create an empty ref
    const routerOuteletRef = ref(null);
    // provide the value in the component hierarchy
    provide("routerOutlet", routerOuteletRef);

    return { routerOuteletRef };
  },
});
