import http from "@/common/http";
import { SERVICE_API_URL } from "@/common/config";

const login = (params: object) => {
  return http.post(SERVICE_API_URL + "/auth/login", params);
};

const register = (params: object) => {
  return http.post(SERVICE_API_URL + "/auth/register", params);
};

const email = (params: object) => {
  return http.post(SERVICE_API_URL + "/auth/email", params);
};

const user = () => {
  return http.get(SERVICE_API_URL + "/auth/user");
};

const recover = (params: object) => {
  return http.post(SERVICE_API_URL + "/auth/recover", params);
};

const emailGet = (params: object) => {
  return http.get(SERVICE_API_URL + "/auth/email", {
    params: params
  });
};
const loginid = (params: object) => {
  return http.get(SERVICE_API_URL + "/auth/loginid", {
    params: params
  });
};

const logout = () => {
  return http.post(SERVICE_API_URL + "/auth/logout");
};

const withdrawal = (params: object) => {
  return http.post(SERVICE_API_URL + "/auth/withdrawal", params);
};

export default {
  login,
  logout,
  register,
  email,
  emailGet,
  loginid,
  recover,
  user,
  withdrawal
}