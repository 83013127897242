import CryptoJS from "crypto-js";

export const Crypto = {
  /**
   * salt=BA69D51982BA3490
   * key=8BAE8BA5DE7A43F377E4DA3997304FD5
   * iv =31ABF2F208F80587EEAE0B93CA714233
   */
  cryptoAesEncrypt(value: string) {
    const aesKey = CryptoJS.enc.Utf8.parse("8BAE8BA5DE7A43F377E4DA3997304FD5");
    const iv = CryptoJS.enc.Utf8.parse("31ABF2F208F80587EEAE0B93CA714233");
    const encrypted = CryptoJS.AES.encrypt(String(value), aesKey, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    }).toString();
    return encrypted;
  },
  cryptoAesDecrypt(value: string) {
    const aesKey = CryptoJS.enc.Utf8.parse("8BAE8BA5DE7A43F377E4DA3997304FD5");
    const iv = CryptoJS.enc.Utf8.parse("31ABF2F208F80587EEAE0B93CA714233");
    const encrypted = CryptoJS.AES.decrypt(String(value), aesKey, {
      iv: iv,
      padding: CryptoJS.pad.Pkcs7,
      mode: CryptoJS.mode.CBC
    }).toString(CryptoJS.enc.Utf8);
    return encrypted;
  }
};

export default Crypto;
