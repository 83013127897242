import Jwt from "@/common/jwt";
// import router from "../router";
// api
// import { API_URL } from "@/common/config";

const interceptor = (axiosInstance: any) => (error: any) => {
  const _axios = axiosInstance;
  // const originalRequest: any = error.config;

  console.log(_axios);
  if (error.response) {
    if (error.response.status == 401) {
      // 리프레시토큰 요청후 실패 > 로그아웃
      console.log("리프레시토큰 요청후 실패 > 로그아웃");
      Jwt.destroyRefreshToken;
      Jwt.destroyAccessToken();
      window.location.replace("/");
    }
  }

  return Promise.reject(error);
};

export default interceptor;
